
import './style/common.scss';
import './style/font.css'
import './style/mobile.scss'
//import './style/mobile.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './component/Header';
import Footer from './component/Footer';
import Main from "./page/Main";
import About from "./page/About";
import Solution from "./page/Solution";
import Contact from "./page/Contact";
import Test from './page/Test'
function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path="/test" element={<Test />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/solution" element={<Solution />} />
        <Route exact path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
