
import {useState} from 'react'
function Solution() {
    const mobileYn = useState(window.innerWidth<900?true:false);

    return (
        <div className='contents_wrap'>
            <div className="solution s1" style={{backgroundImage: "url('/img/back3"+(mobileYn?"_m":"")+".jpg')"}}>
                <div className="page_title">Solutions</div>
                <div className="main_copy">
                    Infinite Power <br />
                    of Soft Solutions
                </div>
                <div className="tag_items">
                    <div className="tag_item">Cushioning</div>
                    <div className="tag_item">Elastic</div>
                    <div className="tag_item">Versatile</div>
                </div>
                <div className="col2_container">
                    <div className="main_copy">
                        We see <br />
                        the infinite power <br />
                        of softness.
                    </div>
                    <div className="main_desc">
                        CTC specializes in soft solutions that make
                        the world a more comfortable, safe, and flexible place. Careful consideration of material end use underpins compounding
                        and processing techniques.
                    </div>
                </div>
            </div>
            <div className="solution s2">
                <div className="col2_container">
                    <div className="main_copy">
                        Desired properties are precisely regulated and optimized for end use.
                    </div>
                    <img src="/img/ic_solution1.svg" />
                </div>
                <div className="spec_items">
                    <div className="spec_item">
                        <div className="spec_header">
                            <div className="spec_title">Tenderate</div>
                            <div className="spec_subtitle">Chemical Foaming</div>
                        </div>
                        <div className="spec_contents">
                            A TPE-based foam with softness, density,
                            and resilience levels that can be tailored
                            through various blending methods.
                            A material that simultaneously achieves
                            high customizability and easy quality
                            control.
                            <ul>
                                <li>Closed cell soft foams</li>
                                <li>Asker C 20C - 70C</li>
                                <li>Density 0.12 - 0.45 g/cc</li>
                                <li>EU REACH compliance </li>
                            </ul>
                        </div>
                    </div>
                    <div className="spec_item">
                        <div className="spec_header">
                            <div className="spec_title">Whistling</div>
                            <div className="spec_subtitle">Physical Injection Foaming</div>
                        </div>
                        <div className="spec_contents">
                        A novel EVA material that makes it
possible to achieve and retain shape
precision and sharp edges on foam like
never before. Manufactured with zero
water or chemicals, it is the world’s first
carbon negative EVA.
                            <ul>
                                <li>Sustainable foaming process</li>
                                <li>Less carbon footprint vs. Chemical foaming</li>
                                <li>Higher recyclability</li>
                                <li>Zero VOC</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="solution s3" style={{ backgroundImage: "url('/img/solution2.jpg')" }}></div>



        </div>
    )


}

export default Solution;
