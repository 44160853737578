import {useState} from 'react'

function Contact() {
    const mobileYn = useState(window.innerWidth<900?true:false);

    return (
        <div className='contents_wrap'>
            <div className="contact c1"  style={{backgroundImage: "url('/img/back4"+(mobileYn?"_m":"")+".jpg')"}}>
                <div className="page_title">Contact</div>
                <div className="main_copy">
                Get in touch
                </div>

            </div>
            <div className="contact c2">
                <div className="address">
                ctc@comtechchemical.kr<br/>
                +82 55 327 9611<br/>
                <br/>
                8-48 727 Bunseong-ro<br/>
                Gimhae Gyeongnam<br/>
                Busan South Korea 50827
                </div>

            </div>
            <div className="contact c3" style={{ backgroundImage: "url('/img/contact3.jpg')" }}></div>

        </div>
    )


}

export default Contact;
