
import {useState} from 'react'

function About() {

    const mobileYn = useState(window.innerWidth<900?true:false);
    return (
        <div className='contents_wrap'>
            <div className="about a1" style={{backgroundImage: "url('/img/back2"+(mobileYn?"_m":"")+".jpg')"}}>
            <div className="page_title">About</div>
                <div className="main_copy">
                    Driving Material <br />
                    Innovation
                </div>
                <div className="tag_items">
                    <div className="tag_item">Catalyze</div>
                    <div className="tag_item">Convert</div>
                    <div className="tag_item">Refine</div>
                </div>
                <div className="col2_container">
                    <div className="spec_items">
                        <div className="spec_item">
                            <img src="/img/ic_plus_b.svg" />
                            <div className="spec_title">
                                Soft. <br />
                                Strong.
                            </div>
                        </div>
                        <div className="spec_item">
                            <img src="/img/ic_plus_b.svg" />
                            <div className="spec_title">
                                Light. <br />
                                Durable.
                            </div>
                        </div>
                        <div className="spec_item">
                            <img src="/img/ic_plus_b.svg" />
                            <div className="spec_title">
                                Cushioning. <br />
                                Responsive.
                            </div>
                        </div>
                    </div>
                    <div className="spec_desc">
                        <div>
                            At CTC, we reimagine the potential of
                            materials to elevate the textures of life.
                        </div>
                        <div>
                            We ceaselessly research and develop the science of tactility. Through advanced compounding and processing methods,
                            we engineer materials that achieve a
                            balance of diverse properties.
                        </div>
                    </div>
                </div>
            </div>
            <div className="about a2">
                <div className="col2_container">
                    <div className="main_copy">
                        We specialize in <br />
                        soft foam development.
                    </div>
                    <img src="/img/ic_about.svg" />
                </div>
                <div className="spec_items">
                    <div className="spec_item">
                        <div className="spec_title">
                            <span className="spec_number">1</span>Chemical Foaming
                        </div>
                        <ul className="spec_contents">
                            <li>
                                Injection molding <br />
                                (Tenderate product)
                            </li>
                            <li>
                                Compression molding
                            </li>
                        </ul>
                    </div>
                    <div className="spec_item">
                        <div className="spec_title">
                            <span className="spec_number">2</span>Physical Injection Foaming
                        </div>
                        <ul className="spec_contents">
                            <li>
                                Autoclave supercritical physical
                                foaming
                            </li>
                            <li>
                                Injection physical foaming <br />
                                (Whistling Solution)
                            </li>
                            <li>
                                Steam chest bead foaming
                            </li>
                        </ul>
                    </div>
                    <div className="spec_item">
                        <div className="spec_title">
                            <span className="spec_number">1</span>Compound Development
                        </div>
                        <ul className="spec_contents">
                            <li>
                                TPE, POE, EVA, SEBS, NR,
                                bio-based resins, PA, TPEE, TPU, PLA, PET, r-PET
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="about a3"  style={{ backgroundImage: "url('/img/about2.jpg')" }}></div>



        </div>
    )


}

export default About;
