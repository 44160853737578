
function Footer() {


    return (
        <footer className='footer'>
            <div className="ft_logo">
                <img src="/img/logo_footer.svg"/>
            </div>
            <div className="ft_copyright">
            © 2022 CTC. All Rights Reserved.
            </div>

        </footer>
    )


}

export default Footer;
