import { useEffect, useState } from 'react';
import {  useLocation } from "react-router-dom";
import $ from 'jquery'
function Header() {
    const [menuActive,setMenuActive] = useState(false);
    const location = useLocation();
  
    useEffect(()=>{

     
    },[]);
    const menuClick=(target)=>{
      
        setMenuActive(false);
        $('html, body').animate({
            scrollTop: $(target).offset().top-(window.innerWidth<900?48:68)
         }, 500);
    }
    
    return (
        <header className={'header '+(menuActive?'active':'')}>
            <div className='logo_section'><a href="/"><img src="/img/logo.svg" /></a></div>
            <div className='mobile_menu' onClick={(e)=>{setMenuActive(!menuActive)}}>
                <div className='mm_bar mm1'></div>
                <div className='mm_bar mm2'></div>
                <div className='mm_bar mm3'></div>
            </div>
            <ul className='main_menu'>
                <li className={location.pathname==="/about"?'active':''}><a onClick={()=>{menuClick('.a1')}}>Story</a></li>
                <li className={location.pathname==="/solution"?'active':''}><a  onClick={()=>{menuClick('.s1')}}>Solutions</a></li>
                <li className={location.pathname==="/contact"?'active':''}><a  onClick={()=>{menuClick('.c1')}}>Contact</a></li>
                <li><a  onClick={()=>{menuClick('.c2 .career')}}>Career</a></li>

            </ul>
         

        </header>
    )


}

export default Header;
