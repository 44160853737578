
import { useState,useRef,useEffect } from 'react'
function Test() {
    const mobileYn = useState(window.innerWidth < 900 ? true : false);
    const videoParentRef = useRef();
   
    useEffect(() => {
      
      }, []);
    return (
        <div className='contents_wrap'>
       
<video x5-video-player-type="h5" autoPlay="true" muted="true"
webkit-playsinline="true" 
playsinline="true"
x-webkit-airplay="allow"

src='/video/video_test.mov'

>
  
  
</video>

        </div>
    )


}

export default Test;
